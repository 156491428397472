import $ from "jquery";
import './import/modules';
// import Chart from 'chart.js';

$(document).ready(function(){
	if (window.innerWidth < 479) {
		var viewportmeta = document.querySelector('meta[name="viewport"]');
		if (viewportmeta) {
			if (screen.width < 479) {
				var newScale = screen.width / 479;
				viewportmeta.content = `width=479, minimum-scale=${newScale}, maximum-scale=1.0, user-scalable=no, initial-scale=${newScale}`;
			} else {
				viewportmeta.content =
					'width=device-width, maximum-scale=1.0, initial-scale=1.0';
			}
		}
	}

	// Dropdown
	$('.drop').on('click', function () {
		$(this).attr('tabindex', 1).focus();
		$(this).toggleClass('active');
		$(this).find('.drop-list').slideToggle(300);
	});
	$('.drop').focusout(function () {
		$(this).removeClass('active');
		$(this).find('.drop-list').slideUp(300);
	});
	$('.drop-item').click(function () {
		$(this).parents('.drop').find('.drop-text').text($(this).text());
		$(this).parents('.drop').find('input').attr('value', $(this).attr('data-id')).trigger('change');
	});

	// Menu toggle
	$('.menu-btn').on('click', function () {
		$('body').addClass('active-menu');
		$(this).next().addClass('active');
	});

	$(document).mouseup(function (e) {
		let activeMenu = $('.sidebar_mobile');
		if (!activeMenu.is(e.target) && activeMenu.has(e.target).length === 0) {
			$('body').removeClass('active-menu');
			$('.sidebar_mobile').removeClass('active');
		}
	});

	// Toggle
	$('.toggle').on('click', (function () {
    let textBlock = $(this).parent().next();
    $(this).hasClass('active') ? ($(this).removeClass('active'), textBlock.slideUp(300))  : ($(this).addClass('active'), textBlock.slideToggle(300))
	}));
	
	// Chart
	// let ctx = document.getElementById('myChart');
	// let myChart = new Chart(ctx, {
  //   type: 'bar',
  //   data: {
  //       labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  //       datasets: [{
  //           label: '$',
  //           data: [319, 311],
  //           backgroundColor: [
  //               'rgba(255, 99, 132, 0.2)',
  //               'rgba(54, 162, 235, 0.2)'
  //           ],
  //           borderColor: [
  //               'rgba(255, 99, 132, 1)',
  //               'rgba(54, 162, 235, 1)'
  //           ],
  //           borderWidth: 1
  //       }]
  //   },
  //   options: {
  //       scales: {
  //           yAxes: [{
  //               ticks: {
  //                   beginAtZero: true
  //               }
  //           }]
  //       }
	// 		}
	// });
});

// function copy() {
// 	document.querySelector('.copy-target').value.trim() &&
// 		window.navigator.clipboard.writeText(
// 			document.querySelector('.copy-target').value.trim()
// 		);
// 	document.querySelector('.copied__notification').classList.add('active');
// 	setTimeout(() => {
// 		document.querySelector('.copied__notification').classList.remove('active');
// 	}, 2000);
// }

// TABS
// document.querySelectorAll('.js-tab-link').forEach((el) => {
// 	el.addEventListener('click', function(ev) {
// 		ev.preventDefault();
// 		var id = this.getAttribute('data-tab'), // 1
// 			content = document.querySelector('.js-tab[data-tab="' + id + '"]'); // 2

// 		document.querySelector('.js-tab-link.active').classList.remove('active'); // 1
// 		el.classList.add('active'); // 2

// 		document.querySelector('.js-tab.active').classList.remove('active'); // 3
// 		content.classList.add('active'); // 4
// 	});
// });

// var jsTriggers = document.querySelectorAll('.js-tab-link'),
// 	jsContents = document.querySelectorAll('.js-tab');
// jsTriggers.forEach(function(trigger) {
// 	trigger.addEventListener('click', function() {
// 		var id = this.getAttribute('data-tab'),
// 			content = document.querySelector('.js-tab[data-tab="' + id + '"]'),
// 			activeTrigger = document.querySelectorAll('.js-tab-link.active'),
// 			activeContent = document.querySelector('.js-tab.active');

// 		activeTrigger.forEach((el) => {
// 			el.classList.remove('active'); // 1
// 		});
// 		trigger.classList.add('active'); // 2

// 		activeContent.classList.remove('active'); // 3
// 		content.classList.add('active'); // 4
// 	});
// });