const plugin = {}

function _createModal(options) {
    const modal = document.createElement('div');
    modal.classList.add('modal');
    modal.insertAdjacentHTML('afterbegin', options.html);
    // const modals = document.querySelector('.modals');
    // modals.appendChild(modal);
    document.body.appendChild(modal);
    return modal;
}

plugin.modal = function(options) {
    const ANIM_SPEED = 200;
    const $modal = _createModal(options);
    let isClose = false;
    let isDestroyed = false;

    // window.addEventListener('scroll', () => {
    //     document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    // });

    const modal = {
        open() {
            if (isDestroyed) {
                return console.log('Modal is destroyed.');
            }
            document.body.classList.add('active-modal');
            const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollY}`;

            
            !isClose && $modal.classList.add('active');
        },
        close() {
            isClose = true;
            $modal.classList.remove('active');
            $modal.classList.add('hidden');
            setTimeout(() => {
                $modal.classList.remove('hidden');
                isClose = false;
            }, ANIM_SPEED);
            document.body.classList.remove('active-modal');
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }

    const listener = e => {
        if (e.target.dataset.close) {
            modal.close();
        }
    }

    $modal.addEventListener('click', listener);

    return modal;
}

// const feedbackModal = plugin.modal({
// 	html: `
// 		<div class="modal__overlay" data-close="true">
// 			<div class="modal__content">
// 				<div class="modal__content-block">
// 					<div class="modal__content-inner">
// 						<h5 class="modal__title" data-translate><span>У вас есть <b>вопрос?</b></span> // <span>Do you have a <b>question?</b></span></h5>
// 						<form action="" class="modal__form feedback-form">
// 							<input type="hidden" name="site" value="McAfee SOC Forum">
// 							<input type="hidden" name="admin" value="marketing@softprom.com">
// 							<div class="modal__form-row">
// 								<label class="modal__label" data-translate>Ваше имя // Your name</label>
// 								<input class="modal__input" type="text" name="name" placeholder="Ваше имя" required>
// 							</div>
// 							<div class="modal__form-row">
// 								<label class="modal__label" data-translate>Телефон // Phone</label>
// 								<input class="modal__input" type="text" name="phone" placeholder="Телефон" required>
// 							</div>
// 							<div class="modal__form-row">
// 								<label class="modal__label" data-translate>Сообщение // Message</label>
// 								<textarea class="modal__textarea" name="message" placeholder="Сообщение"></textarea>
// 							</div>
// 							<button class="modal__btn" type="submit" data-translate>Задать вопрос // Ask a Question</button>
// 						</form>
// 					</div>
// 					<div class="modal__close-btn" data-close="true"></div>
// 				</div>
// 			</div>
// 		</div>
// 	`
// });